import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AuthContextProps {
    loggedIn: boolean;
    userId: string | null;  // 사용자 아이디 상태 추가
    login: (id: string) => void;  // 로그인 함수에 아이디 매개변수 추가
    logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(() => {
        return !!localStorage.getItem('loggedIn');
    });
    const [userId, setUserId] = useState<string | null>(() => {
        return localStorage.getItem('userId') || null;
    });

    const login = (id: string) => {
        setLoggedIn(true);
        setUserId(id);
        localStorage.setItem('loggedIn', 'true');
        localStorage.setItem('userId', id);
    };

    const logout = () => {
        setLoggedIn(false);
        setUserId(null);
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('userId');
    };

    return (
        <AuthContext.Provider value={{ loggedIn, userId, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

import React from "react";
import "./style.css";
import { useAuth } from "contexts/authContext";

interface Props {
  className: string;
  kind: string;
  place: string;
  duration: string;
  src: string;
  onClick?: () => void; // Optional onClick function
}

export const Photo = ({ className, kind, place, duration, src, onClick }: Props): JSX.Element => {
  const { userId } = useAuth();
  return (
    <div 
        className={`photo ${className} ${onClick ? 'clickable' : ''}`} 
        onClick={onClick}
        style={(userId && ["pbc", "sik", "admin"].includes(userId)) ? { cursor: 'pointer' } : {}}
    >
      <div className="overlap-group-2">
        <img className="img" alt="Image" src={src} />
        <div className="rectangle-2" />
        <p className="element">
          종류: {kind}
          <br />
          장소: {place}
          <br />
          기간: {duration}
        </p>
      </div>
    </div>
  );
};

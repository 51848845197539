/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

interface Props {
  className: any;
}

export const UnderBar = ({ className }: Props): JSX.Element => {
  return (
    <div className={`under-bar ${className}`}>
      <div className="frame">
        <div className="div">
          <div className="div-20">회사소개</div>
          <div className="div-20">개인정보취급방침</div>
          <div className="div-20">서비스이용약관</div>
        </div>
        <div className="frame-2">
          <p className="div-20">
            <span className="span">주식회사 제일환경기술 </span>
            <span className="text-wrapper-2">
              | 사업자 번호 : 475-81-01027 | TEL : 033-334-7334 | FAX : 033-334-7336 | 주소 : 강원특별자치도 평창군 평창읍 농공단지길 23-9
            </span>
          </p>
          {/* <p className="p"></p> */}
        </div>
        <div className="frame-2">
          <p className="div-20">
            <span className="span">주식회사 제일환경엔지니어링 </span>
            <span className="text-wrapper-2">
              | 사업자 번호 : 210-11-14408 | TEL : 033-372-3721 | FAX : 033-373-3731 | 주소 : 강원특별자치도 영월군 영월읍 팔괴1농공단지길 4
            </span>
          </p>
          {/* <p className="p"></p> */}
        </div>
        <div className="frame-2">
          <p className="p">대표 : 박성식 | 핸드폰 : 010-3194-1500 | E-mail : sikpark@gmail.com</p>
        </div>
        <p className="p">Copyright © 주식회사 제일환경기술. All rights reserved.</p>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { Photo } from "components/Photo";
import { sendGetRequest } from 'utils/api'
import "./style.css";

export const PhotoGridHome = (): JSX.Element => {
  const [photos, setPhotos] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const data = await sendGetRequest('fetch-images?count=5', {});
        
        if (data && data.length > 0) {
          setPhotos(data);
        } else {
          console.error('Error fetching photos:', data.error);
        }
      } catch (error) {
        console.error('Network or server error:', error);
      }
    };

    fetchPhotos();
  }, []);

  return (
    <div className="photo-grid-home">
      {photos.map((photo, index) => (
        <Photo 
          key={index}
          className="photo-instance" 
          src={`${window.location.origin}${photo.imageUrl}`} 
          kind={photo.kind}
          place={photo.place}
          duration={photo.duration}
        />
      ))}
    </div>
  );
};
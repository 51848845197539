import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "contexts/authContext";

import { UpperMenu } from "components/UpperMenu";
import Logo from 'assets/Images/logo.png';
import "./UpperBar.css";

export const UpperBar = (): JSX.Element => {
    const navigate = useNavigate();
    const { loggedIn, logout, userId } = useAuth();

    const [showSubMenu1, setShowSubMenu1] = useState(false);
    const [showSubMenu2, setShowSubMenu2] = useState(false);
    const [showSubMenu3, setShowSubMenu3] = useState(false);
    const [showSubMenu4, setShowSubMenu4] = useState(false);

    const handleLogoClick = () => {
        navigate("/");
    };

    const handleLogoutClick = () => {
        logout();
        // navigate("/");
    };

    const handleLoginClick = () => {
        navigate("/login");
    };

    return (
        <div className="upper-bar">
            <div className="frame">
                {!loggedIn ? (
                    <>
                        <div className="div" onClick={handleLoginClick} style={{cursor: "pointer"}}>로그인</div>
                        <div className="text-wrapper-2" style={{cursor: "pointer"}}>회원가입</div>
                    </>
                ) : (
                    <>
                        <div className="div">{userId}님</div>
                        <div className="text-wrapper-2" onClick={handleLogoutClick} style={{cursor: "pointer"}}>로그아웃</div>
                    </>
                )}
            </div>
            <div className="frame-2">
                <div className="frame-3" onClick={handleLogoClick}>
                    <img className="logo" alt="Logo" src={Logo} />
                    <div className="flexcontainer">
                        <p className="text-i">
                            <span className="span">
                                (주) 제일환경기술
                                <br />
                            </span>
                        </p>
                        <p className="text-i">
                            <span className="span">(주) 제일환경엔지니어링</span>
                        </p>
                    </div>
                </div>

                <div
                    onMouseEnter={() => setShowSubMenu1(true)}
                    onMouseLeave={() => setShowSubMenu1(false)}
                    style={{position: 'relative'}} 
                >
                    <UpperMenu onClick={() => navigate("/ceo-message")} className="upper-menu-instance" text="회사 소개" active={showSubMenu1} />
                    
                    {showSubMenu1 && (
                        <div className="sub-menu">
                            <button onClick={() => navigate("/ceo-message")}>대표이사 인사말</button>
                            <button onClick={() => navigate("/history")}>연혁</button>
                            <button onClick={() => navigate("/map")}>오시는 길</button>
                        </div>
                    )}
                </div>
                <div
                    onMouseEnter={() => setShowSubMenu2(true)}
                    onMouseLeave={() => setShowSubMenu2(false)}
                    style={{position: 'relative'}} 
                >
                    <UpperMenu onClick={() => navigate("/work-intro")} className="upper-menu-instance" text="사업 소개" active={showSubMenu2} />
                    
                    {showSubMenu2 && (
                        <div className="sub-menu">
                            <button onClick={() => navigate("/work-intro")}>사업 소개</button>
                        </div>
                    )}
                </div>
                <div
                    onMouseEnter={() => setShowSubMenu3(true)}
                    onMouseLeave={() => setShowSubMenu3(false)}
                    style={{position: 'relative'}} 
                >
                    <UpperMenu onClick={() => navigate("/work-gallery")} className="upper-menu-instance" text="사업 실적" active={showSubMenu3} />
                    
                    {showSubMenu3 && (
                        <div className="sub-menu">
                            <button onClick={() => navigate("/work-gallery")}>사업 실적</button>
                        </div>
                    )}
                </div>
                <div
                    onMouseEnter={() => setShowSubMenu4(true)}
                    onMouseLeave={() => setShowSubMenu4(false)}
                    style={{position: 'relative'}} 
                >
                    <UpperMenu onClick={() => navigate("/qna")} className="upper-menu-instance" text="문의 사항" active={showSubMenu4} />
                    
                    {showSubMenu4 && (
                        <div className="sub-menu">
                            <button onClick={() => navigate("/qna")}>문의 및 견적 요청</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

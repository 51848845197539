import React from "react";
import { Banner } from "components/Banner";
import { UnderBar } from "components/UnderBar";
import { UpperMenu } from "components/UpperMenu";
import "./History.css";
import { UpperBar } from "components/UpperBar";

export const History = (): JSX.Element => {
    return (
        <div className="history">
            <div className="div-2">
                <UpperBar/>
                <UnderBar className="under-bar-instance" />
                <div className="text-wrapper-9">연혁</div>
                <Banner className="banner-instance"/>
                <div className="flexcontainer-2">
                    <p className="text">
                        <span className="text-wrapper-10">
                            2009년
                            <br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-11">
                            제일환경엔지니어링 설립
                            <br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-11">
                            영월에 본사를 두고, 펌프 시공, 정화조 시공, 상하수도 시공 등의 사업을 주력으로 진행
                            <br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-11">
                            <br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-10">
                            2018년
                            <br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-11">
                            제일환경기술 설립
                            <br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-11">
                            평창에 본사를 두며 신규 공장 건설
                            <br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-11">
                            제일환경기술은 펌프 제조, 시설물 제조 등의 업무로 사업 확장
                            <br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-11">
                            <br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-10">
                            2024년 (예정)
                            <br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-11">영월에 위치한 신규 공장 신축</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

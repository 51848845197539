import React from "react";
import { Banner } from "components/Banner";
import { QnaList } from "components/QnaList";
import { UnderBar } from "components/UnderBar";
import { UpperBar } from "components/UpperBar";
import "./Qna.css";

export const Qna = (): JSX.Element => {
    return (
        <div className="qna-screen">
            <div className="qna-2">
                <UpperBar/>
                <UnderBar className="under-bar-instance" />
                <div className="text-wrapper-15">문의 및 견적요청</div>
                <p className="text-wrapper-16">문의 및 견적요청은 아래 게시판에 작성해주시기 바랍니다.</p>
                <Banner className="banner-instance"/>
                <div className="frame-6">
                    <div className="text-wrapper-17">&lt;&lt;처음으로</div>
                    <div className="text-wrapper-18">&lt;이전</div>
                    <div className="text-wrapper-18">1/1</div>
                    <div className="text-wrapper-18">다음&gt;</div>
                    <div className="text-wrapper-18">끝으로&gt;&gt;</div>
                </div>
                <div className="group">
                    <div className="overlap">
                        <div className="text-wrapper-19">글쓰기</div>
                    </div>
                </div>
                <div className="frame-7">
                    <QnaList className={"main"} number={"번호"} title={"제목"} man={"담당자"} callsign={"직함"} contact={"전화번호"} date={"작성일"} />
                    <QnaList number={0} title={""} man={""} callsign={""} contact={""} date={""} />
                    <QnaList number={0} title={""} man={""} callsign={""} contact={""} date={""} />
                    <QnaList number={0} title={""} man={""} callsign={""} contact={""} date={""} />
                    <QnaList number={0} title={""} man={""} callsign={""} contact={""} date={""} />
                    <QnaList number={0} title={""} man={""} callsign={""} contact={""} date={""} />
                    <QnaList number={0} title={""} man={""} callsign={""} contact={""} date={""} />
                    <QnaList number={0} title={""} man={""} callsign={""} contact={""} date={""} />
                    <QnaList number={0} title={""} man={""} callsign={""} contact={""} date={""} />
                </div>
                <div className="frame-8">
                    <div className="overlap-group-wrapper">
                        <div className="div-wrapper">
                            <div className="text-wrapper-20">제목</div>
                        </div>
                    </div>
                    <input className="rectangle-2" />
                    <div className="overlap-wrapper">
                        <div className="overlap-2">
                            <div className="text-wrapper-21">검색</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React from "react";
import { Banner } from "components/Banner";
import { UnderBar } from "components/UnderBar";
import "./WorkIntro.css";
import { UpperBar } from "components/UpperBar";
import intro1 from "assets/Images/work_intro_01.jpg"
import intro2 from "assets/Images/work_intro_02.jpeg"
import intro3 from "assets/Images/work_intro_03.jpg"

export const WorkIntro = (): JSX.Element => {
    return (
        <div className="work-intro">
            <div className="div-2">
                <UpperBar/>
                <UnderBar className="under-bar-instance" />
                <div className="text-wrapper-9">사업 소개</div>
                <div className="text-wrapper-10">제일환경이 진행중인 사업을 소개합니다.</div>
                <img className="group" src={intro1}/>
                <img className="group-2" src={intro2}/>
                <img className="group-3" src={intro3}/>
                <Banner className="banner-instance" />
                <p className="element">
                    <span className="text-wrapper-11">
                        1. 상하수도 설비, 기계 설비 시공
                        <br />
                    </span>
                    <span className="text-wrapper-12">
                        상하수도 시설은 생활환경의 품질과 밀접한 관련이 있습니다. 제일환경기술은 전문 인력과 최첨단 기술로 상하수도
                        시설 시공을 전문적으로 수행합니다. 안전하고 효율적인 상하수도 시스템 구축을 위한 다양한 솔루션을 제공하며,
                        고객 만족도를 최우선으로 생각합니다.
                    </span>
                </p>
                <p className="element-2">
                    <span className="text-wrapper-11">
                        2. 펌프 및 정폐수기기 제조
                        <br />
                    </span>
                    <span className="text-wrapper-12">
                        제일환경기술은 고객의 다양한 요구에 부응하는 펌프 및 시설물을 제조하고 있습니다. 고급 소재와 첨단 기술을
                        활용하여 제품의 효율성과 내구성을 극대화합니다. 그 결과, 제일환경기술의 제품은 국내외 다양한 프로젝트에서
                        높은 성능과 신뢰도로 인정받고 있습니다.
                    </span>
                </p>
                <p className="element-3">
                    <span className="text-wrapper-11">
                        3. 개인하수처리시설 (정화조) 설계 및 시공
                        <br />
                    </span>
                    <span className="text-wrapper-12">
                        제일환경기술은 하수처리시설 시공 분야에서 오랜 경험과 노하우를 보유하고 있습니다. 고객의 요구 사항을 정확히
                        파악하여 최적화된 설계와 효율적인 시공을 진행합니다. 지속 가능한 환경 보호를 위해 최신 기술을 도입하여
                        정화조의 안정적인 운영을 지원합니다.
                    </span>
                </p>
            </div>
        </div>
    );
};

import React from "react";
import { Banner } from "components/Banner";
import { UnderBar } from "components/UnderBar";
import { UpperBar } from "components/UpperBar";
import "./CeoGreet.css";

export const CeoGreet = (): JSX.Element => {
    return (
        <div className="ceo-greet">
            <div className="div-3">
                <UpperBar/>
                <UnderBar className="under-bar-instance" />
                <div className="text-wrapper-9">대표이사 인사말</div>
                {/* <div className="group">
                    <div className="text-wrapper-10">(주) 제일환경기술 대표이사</div>
                    <div className="ellipse" />
                </div> */}
                <p className="text-wrapper-11">
                    안녕하세요, <br />
                    제일환경의 대표 박성식입니다.
                    <br />
                    <br />
                    환경은 인류의 생명과 직접 연결된 중요한 가치입니다. <br />그 가치를 지키기 위해 저희 제일환경은 정화조
                    시공, <br />
                    상하수도 시설 시공, 펌프 제조 및 시공 등의 업무를 통해 <br />
                    환경을 보호하고 개선하는 데 앞장서 왔습니다.
                    <br />
                    고객 여러분의 신뢰와 함께 성장해온 지난 시간 동안의 경험은 <br />
                    저희 회사의 가장 큰 자산입니다. 그동안의 성취는 고객 여러분의 <br />
                    끊임없는 관심과 사랑 덕분이라고 생각합니다. <br />
                    앞으로도 변함없는 지지를 부탁드립니다.
                    <br />
                    <br />
                    저희 제일환경은 기술적 향상은 물론, 고객 서비스의 향상에도 <br />
                    지속적으로 투자하며 항상 고객의 목소리를 듣고, 반영하기 위해 노력하겠습니다. <br />
                    항상 신뢰와 만족을 드리기 위해 최선을 다하겠습니다.
                    <br />
                    제일환경을 믿고 선택해주시는 모든 고객 여러분께 <br />
                    진심으로 감사드립니다. 더 나은 내일을 위해 함께 나아가겠습니다.
                    <br />
                    <br />
                    감사합니다.
                </p>
                <div className="group-2">
                    <div className="text-wrapper-12">(주) 제일환경기술<br/>(주) 제일환경엔지니어링</div>
                    <div className="text-wrapper-13">대표이사 박성식</div>
                </div>
                <Banner className="banner-instance"/>
            </div>
        </div>
    );
};
import React, { useEffect, useRef, useState } from "react";
import { Banner } from "components/Banner";
import { UnderBar } from "components/UnderBar";
import { UpperBar } from "components/UpperBar";
import "./Map.css";

declare var kakao: any;

export const Map = (): JSX.Element => {
    const [selectedLocation, setSelectedLocation] = useState<'factory' | 'engineering'>("factory");
    const mapRef = useRef<any>(null);

    useEffect(() => {
        mapscript();
    }, []);

    useEffect(() => {
        setBounds();
    }, [selectedLocation]);

    const mapscript = () => {
        const locations = {
            "factory": { lat: 37.3953074, lng: 128.40913 },
            "engineering": { lat: 37.1572062, lng: 128.4671616 },
        };

        let container = document.getElementById("map");
        let options = {
            center: new kakao.maps.LatLng(1.000, 1.000),
            level: 3,
        };

        const map = new kakao.maps.Map(container, options);
        mapRef.current = map;

        // let bounds = new kakao.maps.LatLngBounds();
        Object.values(locations).forEach(location => {
            let markerPosition = new kakao.maps.LatLng(location.lat, location.lng);
            let marker = new kakao.maps.Marker({ position: markerPosition });
            marker.setMap(mapRef.current);
            // bounds.extend(markerPosition);
        });
        // map.setBounds(bounds);
    };

    const setBounds = () => {
        if (!mapRef.current) return;

        const locations = {
            "factory": { lat: 37.3953074, lng: 128.40913 },
            "engineering": { lat: 37.1572062, lng: 128.4671616 },
        };

        let bounds = new kakao.maps.LatLngBounds();
        bounds.extend(new kakao.maps.LatLng(locations[selectedLocation].lat, locations[selectedLocation].lng));

        mapRef.current.setBounds(bounds);
    };

    const handleLocationClick = (location: 'factory'|'engineering') => {
        setSelectedLocation(location);
    };

    const renderAddressInfo = () => {
        if (selectedLocation === "factory") {
            return (
                <>
                    <p className="text">
                        <span className="text-wrapper-12">
                            주소: 강원특별자치도 평창군 평창읍 농공단지길 23-9<br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-12">
                            버스: 주진 정류장 310 ~ 316, 380, 381, 390 버스 이용 (평창군청 15분 거리)<br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-12">기차: 평창역 하차 후 택시 이용 (20분 소요)</span>
                    </p>
                </>
            );
        } else if (selectedLocation === "engineering") {
            return (
                <>
                    <p className="text">
                        <span className="text-wrapper-12">
                            주소: 강원특별자치도 영월군 영월읍 팔괴1농공단지길 4<br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-12">
                            버스: 팔괴농공단지앞 정류장 5번 버스 이용<br />
                        </span>
                    </p>
                    <p className="text">
                        <span className="text-wrapper-12">기차: 영월역 하차 후 버스 이용 (10분 소요)</span>
                    </p>
                </>
            );
        }
        return null;
    };

    return (
        <div className="map">
            <div className="div-2">
                <UpperBar/>
                <UnderBar className="under-bar-instance" />
                <div className="text-wrapper-9">오시는 길</div>
                <Banner className="banner-instance"/>
                <div className="overlap">
                    <div 
                        className="group" 
                        onClick={() => handleLocationClick("factory")}
                        style={{ backgroundColor: selectedLocation === "factory" ? "#2f2f2f" : "#d9d9d9", cursor: selectedLocation === "factory" ? "default" : "pointer" }}
                    >
                        <div className="div-wrapper">
                            <div className="text-wrapper-10" style={{color: selectedLocation === "factory" ? "#ffffff" : "#000000"}}>제일환경기술(공장)</div>
                        </div>
                    </div>
                    <div 
                        className="overlap-wrapper" 
                        onClick={() => handleLocationClick("engineering")}
                        style={{ backgroundColor: selectedLocation === "engineering" ? "#2f2f2f" : "#d9d9d9", cursor: selectedLocation === "engineering" ? "default" : "pointer" }}
                    >
                        <div className="overlap-2">
                            <div className="text-wrapper-11" style={{color: selectedLocation === "engineering" ? "#ffffff" : "#000000"}}>제일환경엔지니어링</div>
                        </div>
                    </div>
                </div>
                <div id="map" className="img"></div>
                <div className="flexcontainer-2">
                    {renderAddressInfo()}
                </div>
            </div>
        </div>
    );
};

import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { Banner } from "components/Banner";
import { UpperBar } from "components/UpperBar";
import { sendPostRequest } from "utils/api";
import { useAuth } from "contexts/authContext";
import "./Login.css";

export const Login = (): JSX.Element => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>(''); // state 추가
  const [password, setPassword] = useState<string>(''); // state 추가

  const handleLogin = async () => {
      const data = {
          username: username,
          password: password,
      };
      
      const response = await sendPostRequest('login', data);

      if (response.success) {
          login(username); 
          console.log("로그인 성공");
          navigate("/");
      } else {
          console.log("로그인 실패");
      }
  };
  return (
      <div className="login">
          <div className="div-2">
              <UpperBar/>
              <div className="text-wrapper-5">로그인</div>
              <Banner className="banner-instance"/>
              <input 
                  className="input-id" 
                  placeholder="아이디를 입력하세요" 
                  type="text" 
                  value={username} 
                  onChange={(e) => setUsername(e.target.value)}
              />
              <input 
                  className="input-pw" 
                  placeholder="비밀번호를 입력하세요" 
                  type="password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)}
              />
              <div className="text-wrapper-6">아이디</div>
              <div className="text-wrapper-7">비밀번호</div>
              <div className="overlap" onClick={handleLogin}>
                  <div className="rectangle-4" />
                  <div className="text-wrapper-8">로그인</div>
              </div>
              <div className="text-wrapper-9">아이디 찾기</div>
              <div className="text-wrapper-10">비밀번호 찾기</div>
              <div className="text-wrapper-11">|</div>
              <div className="text-wrapper-12">|</div>
              <p className="p">
                  <span className="text-wrapper-13">아직 계정이 없으신가요?</span>
                  <span className="text-wrapper-14">&nbsp;</span>
                  <span className="text-wrapper-15">회원가입</span>
              </p>
          </div>
      </div>
    );
};

import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "contexts/authContext";
import { sendImageToServer, sendGetRequest, sendDeleteRequestByImageUrl } from 'utils/api';
import { Banner } from "components/Banner";
import { Photo } from "components/Photo";
import { UnderBar } from "components/UnderBar";
import { UpperBar } from "components/UpperBar";
import "./WorkGallery.css";

export const WorkGallery = (): JSX.Element => {
    const [showModal, setShowModal] = useState(false);
    const [previewSrc, setPreviewSrc] = useState("");
    const [kind, setKind] = useState("");
    const [place, setPlace] = useState("");
    const [duration, setDuration] = useState("");
    const { loggedIn, userId } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [images, setImages] = useState<Array<any>>([]);

    useEffect(() => {
        const fetchImageCount = async () => {
            try {
                const data = await sendGetRequest('images/count');
                const pages = Math.ceil(data.count / 10);
                setTotalPages(pages);
            } catch (error) {
                console.error('Failed to fetch image count:', error);
            }
        };

        fetchImageCount();
    }, []);

    useEffect(() => {
        const fetchImagesForPage = async () => {
            try {
                const data = await sendGetRequest(`images/page/${currentPage}`);
                setImages(data);
            } catch (error) {
                console.error('Failed to fetch images for the page:', error);
            }
        };

        fetchImagesForPage();
    }, [currentPage]);

    const handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewSrc(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleWrite = async () => {
        if (!loggedIn) {
            alert("로그인이 필요합니다.");
            setShowModal(false);
            return;
        }
    
        if (userId && !["pbc", "sik", "admin"].includes(userId)) {
            alert("권한이 없습니다.");
            setShowModal(false);
            return;
        }        
        setShowModal(true)
    }

    const handleUpload = async () => {
        if (!kind || !place || !duration || !fileInputRef.current?.files?.length) {
            alert("정보를 모두 입력하세요.");
            return;
        }
        
        if (fileInputRef.current?.files) {
            const file = fileInputRef.current.files[0];
            if (file) {
                const response = await sendImageToServer(file, kind, place, duration);
                if (response.success) {
                    // Reset the state after a successful upload
                    setPreviewSrc("");
                    setKind("");
                    setPlace("");
                    setDuration("");
                    fileInputRef.current.value = ""; // Reset the file input
                    // alert("Image uploaded successfully!");
                } else {
                    // alert("Error uploading image.");
                }
                setShowModal(false);
            }
        }
        window.location.reload()
    };  
    
    const handleDeleteImage = async (imgUrl: string) => {
        if (!loggedIn) {
        //   alert("로그인이 필요합니다.");
          return;
        }
      
        if (userId && !["pbc", "sik", "admin"].includes(userId)) {
        //   alert("권한이 없습니다.");
          return;
        }
      
        const confirmDelete = window.confirm("이미지를 삭제하시겠습니까?");
        if (confirmDelete) {
          try {
            await sendDeleteRequestByImageUrl('images-by-url', imgUrl);
            setCurrentPage(1); // 페이지를 다시 로드하여 삭제된 이미지를 반영
          } catch (error) {
            console.error("Error deleting image:", error);
          }
        }
        window.location.reload()
    };

    return (
        <div className="work-gallery">
            <div className="div-2">
                <UpperBar/>
                <UnderBar className="under-bar-instance" />
                <div className="text-wrapper-9">사업 실적</div>
                <p className="text-wrapper-10">제일환경의 최근 사업 실적을 소개합니다.</p>
                <div className="frame-6">
                    <div className="frame-7">
                        {images.slice(0, 5).map((img, idx) => (
                            <Photo className="photo-instance" onClick={() => handleDeleteImage(img.imageUrl)} key={idx} src={`${window.location.origin}${img.imageUrl}`}  kind={img.kind} place={img.place} duration={img.duration} />
                        ))}
                    </div>
                    <div className="frame-7">
                        {images.slice(5, 10).map((img, idx) => (
                            <Photo className="photo-instance" onClick={() => handleDeleteImage(img.imageUrl)} key={idx} src={`${window.location.origin}${img.imageUrl}`}  kind={img.kind} place={img.place} duration={img.duration} />
                        ))}
                    </div>
                </div>
                <Banner className="banner-instance"/>
                <div className="group">
                    <div className="overlap" onClick={handleWrite}>
                        <div className="text-wrapper-11">글쓰기</div>
                    </div>
                </div>

                {showModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <button className="modal-close" onClick={() => setShowModal(false)}>X</button>
                            {previewSrc && <img className="modal-img" src={previewSrc} alt="Preview" />}
                            <input className="modal-file" type="file" ref={fileInputRef} onChange={handleFileChange} />
                            <input
                                className="modal-kind" 
                                placeholder="Kind"
                                value={kind}
                                onChange={(e) => setKind(e.target.value)}
                            />
                            <input
                                className="modal-place" 
                                placeholder="Place"
                                value={place}
                                onChange={(e) => setPlace(e.target.value)}
                            />
                            <input
                                className="modal-duration" 
                                placeholder="Duration"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                            />
                            <button className="modal-submit" onClick={handleUpload}>Submit</button>
                        </div>
                    </div>
                )}
                <div className="frame-8">
                    <div 
                        className="text-wrapper-12" 
                        onClick={handleFirstPage} 
                        style={{ cursor: currentPage === 1 ? 'default' : 'pointer' }}
                    >
                        &lt;&lt;처음으로
                    </div>
                    <div 
                        className="text-wrapper-13" 
                        onClick={handlePrevPage} 
                        style={{ cursor: currentPage === 1 ? 'default' : 'pointer' }}
                    >
                        &lt;이전
                    </div>
                    <div className="text-wrapper-13">
                        {currentPage}/{totalPages}
                    </div>
                    <div 
                        className="text-wrapper-13" 
                        onClick={handleNextPage} 
                        style={{ cursor: currentPage === totalPages ? 'default' : 'pointer' }}
                    >
                        다음&gt;
                    </div>
                    <div 
                        className="text-wrapper-13" 
                        onClick={handleLastPage} 
                        style={{ cursor: currentPage === totalPages ? 'default' : 'pointer' }}
                    >
                        끝으로&gt;&gt;
                    </div>
                </div>
            </div>
        </div>
    );
};

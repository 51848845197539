import React from "react";
import "./QnaList.css";

interface Props {
    className?: string;
    number: number | string;
    title: string;
    man: string;
    callsign: string;
    contact: string;
    date: string;
    onClick?: () => void; // Optional onClick function
  }

export const QnaList = ({ className, number, title, man, callsign, contact, date, onClick }: Props): JSX.Element => {
    return (
        <div className={`qna-list${className? " main": ""}`}>
            <div className="text-wrapper">{number}</div>
            <div className="div">{title}</div>
            <div className="text-wrapper-2">{man}</div>
            <div className="text-wrapper-3">{callsign}</div>
            <div className="text-wrapper-4">{contact}</div>
            <div className="text-wrapper-5">{date}</div>
        </div>
    );
};

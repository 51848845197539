import {Routes, Route} from 'react-router-dom'
import Home from './Home'
import { WorkGallery } from 'pages/WorkGallery'
import { Qna } from 'pages/Qna'
import { CeoGreet } from 'pages/CeoGreet'
import { History } from 'pages/History'
import { Map } from 'pages/Map'
import { WorkIntro } from 'pages/WorkIntro'
import { Login } from 'pages/Login'

export default function RouteSetup() {
    return (
        <Routes>
            <Route path= "/" element = {<Home/>}/>
            <Route path= "/ceo-message" element = {<CeoGreet/>}/>
            <Route path= "/history" element = {<History/>}/>
            <Route path= "/map" element = {<Map/>}/>
            <Route path= "/work-intro" element = {<WorkIntro/>}/>
            <Route path= "/work-gallery" element = {<WorkGallery/>}/>
            <Route path= "/qna" element = {<Qna/>}/>
            <Route path= "/login" element = {<Login/>}/>
        </Routes>
    )
}
import React from "react";
import "./style.css";
import banner from 'assets/Images/banner.png';

interface Props {
  className: any;
}

export const Banner = ({ className }: Props): JSX.Element => {
  return (
    <div className={`banner ${className}`}>
      <div className="overlap-group">
        <img className="image" alt="Image" src={banner} />
        <div className="rectangle" />
        <p className="text-wrapper-3">
          맑고 푸른 환경을 위한 초석
          <br />
          최고의 선택은 제일환경입니다.
        </p>
        <div className="text-wrapper-4">제일환경은 고객과 함께합니다.</div>
      </div>
    </div>
  );
};

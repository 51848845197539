import React from "react";
import { useNavigate } from "react-router-dom";
import { Banner } from "components/Banner";
import { PhotoGridHome } from "components/PhotoGridHome";
import { UnderBar } from "components/UnderBar";
import { UpperBar } from "components/UpperBar";
import iconMap from 'assets/Images/icon-map.png';
import iconChat from 'assets/Images/icon-chat.png';
import "./Home.css";

export const Home = (): JSX.Element => {
  const navigate = useNavigate();

  const handleMoreClick = () => {
    navigate("/work-gallery");
  };
  const handleMapClick = () => {
    navigate("/map");
  };
  const handleQnaClick = () => {
    navigate("/qna");
  };
  return (
    <div className="home">
      <div className="div-3">
        <div className="overlap">
          <p className="element-2">
            <span className="text-wrapper-5">대표번호 : </span>
            <span className="text-wrapper-6">
              033-334-7334,
              <br />
            </span>
            <span className="text-wrapper-5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span className="text-wrapper-6">
              033-372-3721
              <br />
            </span>
            <span className="text-wrapper-5">메일 : </span>
            <span className="text-wrapper-6">sikpark@gmail.com</span>
          </p>
          <div className="group" onClick={handleMapClick}>
            <div className="text-wrapper-7">오시는 길</div>
            <img className="group-2" alt="Group" src={iconMap} />
          </div>
          <div className="group-3" onClick={handleQnaClick}>
            <div className="text-wrapper-7">견적의뢰</div>
            <img className="group-4" alt="Group" src={iconChat} />
          </div>
        </div>
        <UpperBar/>
        <UnderBar className="under-bar-instance" />
        <div className="text-wrapper-11">사업 실적</div>
        <p className="text-wrapper-12">제일환경의 최근 사업 실적을 소개합니다.</p>
        <div className="text-wrapper-13">고객 센터</div>
        <p className="text-wrapper-14">신속정확한 답변으로 최상의 서비스를 제공해드립니다.</p>
        <Banner className="banner-instance" />
        <div className="text-wrapper-15" onClick={handleMoreClick}>더보기&gt;</div>
        <PhotoGridHome/>
      </div>
    </div>
  );
};

const sendPostRequest = async (endpoint: string, data: object) => {
  const headers: HeadersInit = {
      'Content-Type': 'application/json'
  };
  
  // FormData의 경우 브라우저가 적절한 'Content-Type' 헤더를 설정하므로
  // 명시적으로 설정한 'Content-Type' 헤더를 제거합니다.
  if (data instanceof FormData) {
      delete headers['Content-Type'];
  }

  const response = await fetch(`${window.location.origin}/${endpoint}`, {
      method: 'POST',
      headers: headers,
      body: data instanceof FormData ? data : JSON.stringify(data),
  });

  return response.json();
};

const sendImageToServer = async (imageFile: File, kind: string, place: string, duration: string) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  formData.append('kind', kind);
  formData.append('place', place);
  formData.append('duration', duration);

  return sendPostRequest('upload', formData);
};

const sendGetRequest = async (endpoint: string, queryParams: Record<string, any> = {}) => {
  const url = new URL(`${window.location.origin}/${endpoint}`);
  Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.append(key, value);
  });

  const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json'
      }
  });

  return response.json();
};

const sendDeleteRequestByImageUrl = async (endpoint: string, imageUrl: string) => {
  const url = new URL(`${window.location.origin}/${endpoint}`);
  const response = await fetch(url.toString(), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ imageUrl })
  });

  const data = await response.json();
  if (!data.success) {
    throw new Error(data.error || 'Error deleting image');
  }
};

export { sendPostRequest, sendImageToServer, sendGetRequest, sendDeleteRequestByImageUrl };
